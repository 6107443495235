/* eslint-disable import/prefer-default-export */
export enum EngagementAbility {
  CAN_SEE_SETTINGS_LEFT_MENU = 'can_see_settings_left_menu',
  CAN_SEE_HR_PRACTICES_LEFT_MENU = 'can_see_hr_practices_left_menu',
  CAN_SEE_SURVEYS_LEFT_MENU = 'can_see_surveys_left_menu',
  CAN_SEE_CONSTRUCTOR_LEFT_MENU = 'can_see_constructor_left_menu',
  CAN_EDIT_ROLES = 'can_edit_roles',
  CAN_EDIT_SURVEY_VIEWING_RIGHTS = 'can_edit_survey_viewing_rights',
  CAN_SEE_ALL_SURVEY_DRAFTS = 'can_see_all_survey_drafts',
  SURVEY_CONSTRUCTOR_BANNER = 'survey_constructor_banner',
  CAN_CREATE_SURVEY = 'can_create_survey',
  CAN_SAVE_SURVEY_TEMPLATE = 'can_save_survey_template',
  CAN_CRUD_ALL_SURVEY_DRAFTS = 'can_crud_all_survey_drafts',
  CAN_CREATE_PRACTICE = 'can_create_practice',
  CAN_DUPLICATE_ANY_SURVEY_FROM_QUEUE = 'can_duplicate_any_survey_from_queue',
  CAN_REMOVE_ALL_SURVEYS_FROM_QUEUE = 'can_remove_all_surveys_from_queue',
  CAN_COPY_LINK_FOR_ANY_QUEUE_SURVEY = 'can_copy_link_for_any_queue_survey',
  CAN_STOP_OR_RESUME_ANY_SURVEY = 'can_stop_or_resume_any_survey',
  CAN_CHANGE_SURVEY_VIEWING_RIGHTS = 'can_change_survey_viewing_rights',
  CAN_DUPLICATE_ANY_ACTIVE_SURVEY = 'can_duplicate_any_active_survey',
  CAN_CRUD_ONLY_OWN_SURVEY_DRAFTS = 'can_crud_only_own_survey_drafts',
  CAN_REMOVE_ONLY_OWN_SURVEYS_FROM_QUEUE = 'can_remove_only_own_surveys_from_queue',
  CAN_DUPLICATE_ONLY_OWN_SURVEY_FROM_QUEUE = 'can_duplicate_only_own_survey_from_queue',
  CAN_COPY_LINK_ONLY_BRANCH_QUEUE_SURVEY = 'can_copy_link_only_branch_queue_survey',
  CAN_SEE_ONLY_OWN_SURVEY_DRAFTS = 'can_see_only_own_survey_drafts',
  CAN_STOP_OR_RESUME_ONLY_OWN_SURVEY = 'can_stop_or_resume_only_own_survey',
  CAN_DUPLICATE_ONLY_OWN_ACTIVE_SURVEY = 'can_duplicate_only_own_active_survey',
  CAN_CREATE_SURVEY_FROM_CSI_TEMPLATE = 'can_create_survey_from_csi_template',
  CAN_ADD_FACTORS_TO_QUESTION = 'can_add_factors_to_question',
  REQUEST_SURVEY_BANNER = 'request_survey_banner',
  CAN_SEE_USER_SURVEYS_LEFT_MENU = 'can_see_user_surveys_left_menu',
  CAN_DUPLICATE_ANY_FINISHED_SURVEY = 'can_duplicate_any_finished_survey',
  CAN_DUPLICATE_ONLY_OWN_FINISHED_SURVEY = 'can_duplicate_only_own_finished_survey',
  CAN_EDIT_ANY_STATUS_SURVEY = 'can_edit_any_status_survey',
  CAN_CREATE_FACTORS = 'can_create_factors',
  CAN_SEE_APPOINTED_SURVEYS = 'can_see_appointed_surveys',
  CAN_EXPORT_RAW_DATA = 'can_export_raw_data',
  CAN_SEE_COMPLEX_REPORTS = 'can_view_complex_reports',
  CAN_CREATE_COMPLEX_REPORTS = 'can_create_complex_reports',
  CAN_START_ANY_SURVEY = 'can_start_any_survey',
  CAN_START_ONLY_OWN_SURVEY = 'can_start_only_own_survey',
  CAN_CHANGE_SURVEY_AUDITORY_COUNT_SOURCE = 'can_change_survey_auditory_count_source',
  CAN_MANAGE_DETECT_FAKES_PROPERTY = 'can_manage_detect_fakes_property',
  CAN_SEE_FAKE_USER_SURVEYS = 'can_see_fake_user_surveys',
  CAN_SEND_REMINDERS = 'can_send_reminders',
  CAN_SEE_COMPETENCIES_LEFT_MENU = 'can_see_competencies_left_menu',
  CAN_RUN_YANDEX_GPT = 'manager_gpt',
}

export enum FeatureFlags {
  USE_HELPDESKEDDY = 'use-helpdeskeddy',
  NEW_LEFT_MENU = 'new_left_menu',
}

export enum TsxassAbility {
  CAN_VIEW_EMPLOYEES_ANALYTICS = 'can_view_employees_analytics',
}

export enum LicenseType {
  ENGAGEMENT__FULL = 'engagement_full',
  TSXASS = 'tsxass',
  ENABLE_PDF_DOWNLOADING = 'enable_pdf_downloading',
  ENABLE_XLSX_STATISTICS_DOWNLOADING = 'xlsx_statistics',
  TSXASS_360_EXCEL = '360_excel',
  TSXASS_360_10_SCALE = '360_10scale',
  GPT_SURVEYS = 'gpt_surveys',
  HIDE_GPT = 'hide_gpt',
}
